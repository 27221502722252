export default (firebaseOptions = {}) => ({
  configuration: 'dev',
  baseUrl: 'https://dev.kampoy.com',
  production: true,
  firebaseConfig: {
    options: {
      apiKey: 'AIzaSyBIPN0Lymv_ra0sBIyanV27CYmw0li0_2M',
      authDomain: 'dev-kampoy.firebaseapp.com',
      projectId: 'dev-kampoy',
      storageBucket: 'dev-kampoy.appspot.com',
      messagingSenderId: '621929258406',
      appId: '1:621929258406:web:86fdcdde3b045304718367',
    },
    ...firebaseOptions,
    vapidKey: 'BBXFdOv9l17UGaIbuIuUQxwD_64HjZUm0U50p5fvZfU6qYx6nWfHwioyBiLhElqbh7bWoeo9u8YpyKpn4qjvg34'
  },
  googleMap: 'AIzaSyAnOGqINU0CYKv6U3wTEroIeKOfTiNDAsI',
  contact: 'contact@kampoy.com',
  stripe: {
    publicKey: 'pk_test_51JAfVXDpRBfMHdGFixSPSVzQqnrwJCv1NLNGbnHnqicHTgDpQgsEnXTYRwTQ74zFweknr37wJ5s0pn5MACESKsKn00MHbnBaH3',
    livemode: false
  },
  useEmulators: false,
  sendgrid: {
    email: 'contact@kampoy.com',
    sandbox: false,
    templateIds: {
      newSeller: 'd-6d1d6fa2b132498384e86450816168d7',
      receipt: 'd-a9d11755ab764dcba8ef5d90cf335509',
      invoice: 'd-99ef9b25c7b54fc7904173c62298a33a',
      monthlyKampoyExpense: 'd-e7c15789bc1549d8bead19fec99a9b07',
      deliveryDeleted: 'd-ec56976280cf453ebb2f40f4402d0e94',
      feeUpdate: 'd-927931956ba8477686e3ffddaca73b89',
      invoiceUpdate: 'd-c0d1fc7ea3b84a00a746f2156b72d7a7',
      invoiceDelete: 'd-46fa2f17700e4c099919c3ffa32f34ce',
    },
  },
  twilio: {
    from: '+33757901202',
    sandbox: true,
  },
})
