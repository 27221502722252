import { ErrorHandler, importProvidersFrom } from '@angular/core';
import { withEnabledBlockingInitialNavigation, withInMemoryScrolling, withRouterConfig, provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { QuicklinkModule } from 'ngx-quicklink';
import { TranslocoRootModule } from './app.transloco.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { RIVE_VERSION } from 'ng-rive';
import { REGION_OR_DOMAIN, FIREBASE_CONFIG, FIRESTORE_SETTINGS } from 'ngfire/tokens';
import { createErrorHandler } from '@sentry/angular';
import env from '@env';

const SENTRY_ERROR_HANDLER = env.production
  ? [{
    provide: ErrorHandler,
    useValue: createErrorHandler({ showDialog: false }),
  }]
  : [];

export default {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslocoRootModule,
      QuicklinkModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: env.production,
        registrationStrategy: 'registerWhenStable',
      })
    ),
    { provide: REGION_OR_DOMAIN, useValue: 'europe-west1' },
    {
      provide: FIREBASE_CONFIG,
      useValue: env.firebaseConfig,
    },
    { provide: FIRESTORE_SETTINGS, useValue: { experimentalAutoDetectLongPolling: true } },
    { provide: RIVE_VERSION, useValue: '1.1.4' },
    ...SENTRY_ERROR_HANDLER,
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter([
      {
        path: '',
        loadChildren: () => import("./buyer/shell.module").then((m) => m.BuyerShellModule),
      },
      {
        path: 's',
        loadChildren: () => import("./seller/shell.module").then((m) => m.SellerShellModule),
      },
      {
        path: 'print',
        loadChildren: () => import("./print/print.module").then(m => m.PrintModule)
      },
      {
        path: 'admin',
        loadChildren: () => import("./admin").then(m => m.routes)
      },
      {
        path: '**',
        data: { animation: 'dialog' },
        loadChildren: () => import("./home/not-found/not-found.module").then((m) => m.NotFoundModule),
      },
    ],
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ anchorScrolling: 'disabled' }),
      withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
  ]
}