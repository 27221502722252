import { enableProdMode } from '@angular/core';
import { init as initSentry } from "@sentry/angular";
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import env from '@env';
import config from './app/config';


if (env.production) {
  enableProdMode();
  initSentry({
    dsn: "https://87fbb82e1d144d0ca4bc1ff376954b66@o756980.ingest.sentry.io/5792265",
    integrations: [],
    tracesSampleRate: 1.0,
    environment: env.configuration
  });
}

document.addEventListener('DOMContentLoaded', () => {
  bootstrapApplication(AppComponent, config)
    .catch((err) => console.error(err));
});
