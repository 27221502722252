import { Component, ViewChild, Inject, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT, NgIf } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ThemeService } from '@utils/theme';
import { TranslocoPreloader } from '@utils/preload.service';
import env from '@env';
import { IconService } from '@utils';
import { DevModeComponent } from '@ui'
import { JsonLDService } from '@seo';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'market-root',
  standalone: true,
  imports: [RouterOutlet, NgIf, DevModeComponent],
  template: `
    <router-outlet></router-outlet>
    <dev-mode *ngIf="devMode" (close)="devMode = false"></dev-mode>
  `,
  // Don't create inline style as it reference a map that is not generated
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  devMode = env.configuration === 'dev' || env.configuration === 'beta';

  @ViewChild(RouterOutlet) outlet?: RouterOutlet;

  constructor(
    @Inject(DOCUMENT) doc: Document,
    theme: ThemeService,
    transloco: TranslocoService,
    translocoPreloader: TranslocoPreloader,
    icons: IconService,
    jsonLD: JsonLDService,
  ) {
    theme.init();
    translocoPreloader.preload(['auth', 'market', 'bucket', 'invoice']);
    doc.documentElement.lang = transloco.getActiveLang();

    icons.register(['google', 'google_cluster', 'kampoy', 'local_payments', 'local_payments_off']);
    icons.registerMaterial();

    firstValueFrom(transloco.load(`home/${transloco.getActiveLang()}`)).then(t => {
      jsonLD.app({
        name: 'Kampoy',
        description: t.seo.description,
        applicationCategory: 'ShoppingApplication',
        countriesSupported: 'fr',
        offers: { '@type': 'Offer', price: 0, priceCurrency: 'EUR' },
        // screenshot: '', 
      })
    })
  }
}
